export const gsapAnimations = () => {
  gsap.registerPlugin(ScrollTrigger);

  // HOMEPAGE
  if (document.querySelector('.coming-soon')) {
    
    gsap.from('.cs-logo', {
      opacity: 0,
      scale: 1.2,
      duration: 1,
      delay: 1,
      ease: 'power1'
    })
    gsap.to('.intro-logo', {
      opacity: 0,
      duration: 1,
      delay: 3,
      ease: 'power1'
    })

    gsap.from('.reversed-logo', {
      opacity: 0,
      scale: 1.2,
      duration: 1,
      delay: 4,
      ease: 'power1'
    })

    gsap.from('.big-headline', {
      x: -75,
      opacity: 0,
      duration: 1,
      delay: 4,
      ease: 'power1'
    })

    gsap.from('.small-headline', {
      opacity: 0,
      scale: 1.2,
      duration: 1,
      delay: 4,
      ease: 'power1'
    })

    gsap.from('.white-outline', {
      opacity: 0,
      scale: 1.2,
      duration: 1,
      delay: 4,
      ease: 'power1'
    })
    
    gsap.from('.footer', {
      opacity: 0,
      duration: 1,
      delay: 4,
      ease: 'power1'
    })
  }
}